/**
 * Provide some convenience functions.
 */

export const merge = (x, y) => ({...x, ...y});

export const concat = ([x, ...xs]) => (x == null) ? [] : x.concat(...xs);

export const isEmpty = (o) => {
    if (typeof(o) !== "object") {
        throw TypeError(`not an object: ${o}`);
    }
    return Object.keys(o).length === 0;
}
