const EMPTY = {};
export default function shallowEqual(a, b) {
  if (a === void 0) {
    a = EMPTY;
  }

  if (b === void 0) {
    b = EMPTY;
  }

  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  return a === b || aKeys.length === bKeys.length && aKeys.every(key => a[key] === b[key]);
}