/**
 * A filter that skips values that are out of line. The Resource Certification
 * Statistics are append-only files where historically some days contain
 * incorrect values. Mainly these values are close to zero or even zero.
 */
const filterAnomalies = (property) => (records) => {
    const [result, _] = records.reduce(([acc, last], x) => {
        const current = x[property];
        if (last === -1) {
            return [acc, current];
        }
        if (current > last*2 || current < last*.5) {
            return [acc, last];
        } else {
            return [[...acc, x], current];
        }
    }, [[], -1]);
    return result;
};

// date certs roas roa-asn roa-v4 roa-v4u roa-v6 roa-v6u
const re = /(\d{4}-\d{2}-\d{2})\t(\d+)\t(\d+)\t(\d+)\t(\d+)\t(\d+)\t(\d+)\t(\d+)(?:\t\d+\t\d+)?/;
const parse = (source) => (line) => {
    const match = line.match(re);
    if (match == null) {
        return [];
    }
    return [{
        source,
        date: match[1],
        certs: parseInt(match[2]),
        roas: parseInt(match[3]),
        "roa-asn": parseInt(match[4]),
        "roa-v4": parseInt(match[5]),
        "roa-v4u": parseInt(match[6]),
        "roa-v6": parseInt(match[7]),
        "roa-v6u": parseInt(match[8]),
    }];
};

export async function loadSource(source, url) {
    const response = await fetch(url);
    if (!response.ok) {
        throw `Failed to fetch URL: ${url}`;
    }
    const [_, ...data] = (await response.text()).split(/\r?\n/);
    const records = data.flatMap(parse (source));
    return filterAnomalies("certs") (records);
}
