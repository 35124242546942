// Use apply as reducer and dispatch functions as actions
export const reduce = (state, action) => action (state);

const Source = (url) => ({url, enabled: true});

export const initState = {
    sources: {
        "AfriNIC": Source("https://lirportal.ripe.net/certification/content/static/statistics/afrinic.tal.txt"),
        "APNIC": Source("https://lirportal.ripe.net/certification/content/static/statistics/apnic.tal.txt"),
        "ARIN": Source("https://lirportal.ripe.net/certification/content/static/statistics/arin.tal.txt"),
        "LACNIC": Source("https://lirportal.ripe.net/certification/content/static/statistics/lacnic.tal.txt"),
        "RIPE NCC": Source("https://lirportal.ripe.net/certification/content/static/statistics/ripencc.tal.txt"),
    },
    loading: false,
    sourceData: {},
};

export const Actions = {
    // Data loading
    loading: (state) => ({...state, loading: true}),
    sourceDataLoaded: (data) => (state) => ({
        ...state,
        loading: false,
        sourceData: data,
        error: null
    }),
    reportError: (error) => (state) => ({...state, loading: false, error}),

    toggleSource: (source, value) => (state) => ({
        ...state,
        sources: {
            ...state.sources,
            [source]: {
                ...state.sources[source],
                enabled: value
            },
        },
    }),
};
